/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./app.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./core/demo-reset-layover/demo-reset-layover.component.ngfactory";
import * as i3 from "./core/demo-reset-layover/demo-reset-layover.component";
import * as i4 from "@angular/common";
import * as i5 from "./core/policy-layover/policy-layover.component.ngfactory";
import * as i6 from "./core/policy-layover/policy-layover.component";
import * as i7 from "./core/tokens";
import * as i8 from "./core/layout/layout.component.ngfactory";
import * as i9 from "./core/layout/layout.component";
import * as i10 from "./app.component";
import * as i11 from "./core/answer.service";
import * as i12 from "./core/tracking.service";
var styles_AppComponent = [i0.styles];
var RenderType_AppComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AppComponent, data: {} });
export { RenderType_AppComponent as RenderType_AppComponent };
function View_AppComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "button", [["class", "demo-reset"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = ((_co.showResetLayover = true) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, [" End session\n"]))], null, null); }
function View_AppComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "svi-demo-reset-layover", [], null, [[null, "close"], [null, "reset"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("close" === en)) {
        var pd_0 = ((_co.showResetLayover = false) !== false);
        ad = (pd_0 && ad);
    } if (("reset" === en)) {
        var pd_1 = (_co.reset() !== false);
        ad = (pd_1 && ad);
    } return ad; }, i2.View_DemoResetLayoverComponent_0, i2.RenderType_DemoResetLayoverComponent)), i1.ɵdid(1, 114688, null, 0, i3.DemoResetLayoverComponent, [], null, { close: "close", reset: "reset" })], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
export function View_AppComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_AppComponent_1)), i1.ɵdid(1, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AppComponent_2)), i1.ɵdid(3, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(4, 0, null, null, 1, "svi-policy-layover", [["link", "../assets/pdf/privacy_policy_users_fr.pdf"], ["linkText", "Privacy policy and terms of use for users (PDF, only available in French)"], ["text", "Smartwielen uses cookies and the session storage of your web browser to improve performance and usability. Using smartwielen involves the processing of sensitive data (e.g., political positions of candidates and users), therefore data and privacy protection according the EU General Data Protection Regulation (GDPR) is a priority. The document below contains a detailed description of our privacy policy as well as the general terms of use for users. We strongly encourage everyone to carefully read this document.\n"]], null, [[null, "hasAccepted"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("hasAccepted" === en)) {
        var pd_0 = (_co.startTracking() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i5.View_PolicyLayoverComponent_0, i5.RenderType_PolicyLayoverComponent)), i1.ɵdid(5, 4243456, null, 0, i6.PolicyLayoverComponent, [i4.Location, i1.PLATFORM_ID, i7.BrowserStorage], { text: [0, "text"], linkText: [1, "linkText"], link: [2, "link"] }, { hasAccepted: "hasAccepted" }), (_l()(), i1.ɵeld(6, 0, null, null, 1, "svi-layout", [], null, null, null, i8.View_LayoutComponent_0, i8.RenderType_LayoutComponent)), i1.ɵdid(7, 114688, null, 0, i9.LayoutComponent, [], null, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.isDemo && _co.showResetButton); _ck(_v, 1, 0, currVal_0); var currVal_1 = (_co.isDemo && _co.showResetLayover); _ck(_v, 3, 0, currVal_1); var currVal_2 = "Smartwielen uses cookies and the session storage of your web browser to improve performance and usability. Using smartwielen involves the processing of sensitive data (e.g., political positions of candidates and users), therefore data and privacy protection according the EU General Data Protection Regulation (GDPR) is a priority. The document below contains a detailed description of our privacy policy as well as the general terms of use for users. We strongly encourage everyone to carefully read this document.\n"; var currVal_3 = "Privacy policy and terms of use for users (PDF, only available in French)"; var currVal_4 = "../assets/pdf/privacy_policy_users_fr.pdf"; _ck(_v, 5, 0, currVal_2, currVal_3, currVal_4); _ck(_v, 7, 0); }, null); }
export function View_AppComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "svi-root", [], null, [[null, "click"], [null, "touchstart"], [null, "touchend"], [null, "touchcancel"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).onActivity($event.target) !== false);
        ad = (pd_0 && ad);
    } if (("touchstart" === en)) {
        var pd_1 = (i1.ɵnov(_v, 1).onActivity($event) !== false);
        ad = (pd_1 && ad);
    } if (("touchend" === en)) {
        var pd_2 = (i1.ɵnov(_v, 1).onActivity($event) !== false);
        ad = (pd_2 && ad);
    } if (("touchcancel" === en)) {
        var pd_3 = (i1.ɵnov(_v, 1).onActivity($event) !== false);
        ad = (pd_3 && ad);
    } return ad; }, View_AppComponent_0, RenderType_AppComponent)), i1.ɵdid(1, 114688, null, 0, i10.AppComponent, [i11.AnswerService, i12.TrackingService, i7.BrowserStorage, i1.LOCALE_ID], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AppComponentNgFactory = i1.ɵccf("svi-root", i10.AppComponent, View_AppComponent_Host_0, {}, {}, []);
export { AppComponentNgFactory as AppComponentNgFactory };
