import { NgModule } from '@angular/core'
import { RouterModule } from '@angular/router'
import { CommonModule } from '@angular/common'
import { LandingPage } from './landing.page'
import { SharedModule } from '../shared/shared.module'

const COMPONENTS = [LandingPage]

@NgModule({
  imports: [CommonModule, RouterModule, SharedModule],
  declarations: COMPONENTS,
  exports: COMPONENTS
})
export class LandingModule {}
