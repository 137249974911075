/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./questionnaire.page.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./navigation/questionnare-navigation.component.ngfactory";
import * as i3 from "./navigation/questionnare-navigation.component";
import * as i4 from "./question/question.component.ngfactory";
import * as i5 from "./question/question.component";
import * as i6 from "./answer/answer.component.ngfactory";
import * as i7 from "./answer/answer.component";
import * as i8 from "./actions/actions.component.ngfactory";
import * as i9 from "./actions/actions.component";
import * as i10 from "../shared/page-content/page-content.component.ngfactory";
import * as i11 from "../shared/page-content/page-content.component";
import * as i12 from "../shared/loading/loading.component.ngfactory";
import * as i13 from "../shared/loading/loading.component";
import * as i14 from "../shared/navbar/navbar.component.ngfactory";
import * as i15 from "../shared/navbar/navbar.component";
import * as i16 from "@angular/common";
import * as i17 from "@angular/router";
import * as i18 from "../core/tokens";
import * as i19 from "../shared/page-title/page-title.component.ngfactory";
import * as i20 from "../shared/page-title/page-title.component";
import * as i21 from "./questionnaire.page";
import * as i22 from "apollo-angular/Apollo";
import * as i23 from "../core/answer.service";
var styles_QuestionnairePage = [i0.styles];
var RenderType_QuestionnairePage = i1.ɵcrt({ encapsulation: 0, styles: styles_QuestionnairePage, data: {} });
export { RenderType_QuestionnairePage as RenderType_QuestionnairePage };
function View_QuestionnairePage_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 9, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "svi-questionnaire-navigation", [], null, [[null, "navigate"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("navigate" === en)) {
        var pd_0 = (_co.jumpToQuestion($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_QuestionnaireNavigationComponent_0, i2.RenderType_QuestionnaireNavigationComponent)), i1.ɵdid(2, 573440, null, 0, i3.QuestionnaireNavigationComponent, [i1.PLATFORM_ID], { questions: [0, "questions"], userAnswers: [1, "userAnswers"], questionIndex: [2, "questionIndex"] }, { navigate: "navigate" }), (_l()(), i1.ɵeld(3, 0, null, null, 1, "svi-questionnaire-question", [], null, null, null, i4.View_QuestionnaireQuestionComponent_0, i4.RenderType_QuestionnaireQuestionComponent)), i1.ɵdid(4, 573440, null, 0, i5.QuestionnaireQuestionComponent, [], { question: [0, "question"], questionIndex: [1, "questionIndex"] }, null), (_l()(), i1.ɵeld(5, 0, null, null, 4, "div", [["class", "svi-questionnaire-answer-actions"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 1, "svi-questionnaire-answer", [], null, [[null, "answerChanged"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("answerChanged" === en)) {
        var pd_0 = (_co.changeAnswer($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i6.View_QuestionnaireAnswerComponent_0, i6.RenderType_QuestionnaireAnswerComponent)), i1.ɵdid(7, 49152, null, 0, i7.QuestionnaireAnswerComponent, [], { userAnswer: [0, "userAnswer"], number: [1, "number"] }, { answerChanged: "answerChanged" }), (_l()(), i1.ɵeld(8, 0, null, null, 1, "svi-questionnaire-actions", [["doneLabel", "See results"]], null, [[null, "navigate"], [null, "done"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("navigate" === en)) {
        var pd_0 = (_co.navigate($event) !== false);
        ad = (pd_0 && ad);
    } if (("done" === en)) {
        var pd_1 = (_co.showResults() !== false);
        ad = (pd_1 && ad);
    } return ad; }, i8.View_QuestionnaireActionsComponent_0, i8.RenderType_QuestionnaireActionsComponent)), i1.ɵdid(9, 49152, null, 0, i9.QuestionnaireActionsComponent, [], { questions: [0, "questions"], questionIndex: [1, "questionIndex"], doneDisabled: [2, "doneDisabled"], doneLabel: [3, "doneLabel"] }, { navigate: "navigate", done: "done" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _v.context.ngIf; var currVal_1 = _co.userAnswers; var currVal_2 = _co.questionIndex; _ck(_v, 2, 0, currVal_0, currVal_1, currVal_2); var currVal_3 = _v.context.ngIf[_co.questionIndex]; var currVal_4 = _co.questionIndex; _ck(_v, 4, 0, currVal_3, currVal_4); var currVal_5 = _co.userAnswers[_co.questionIndex]; var currVal_6 = (_co.questionIndex + 1); _ck(_v, 7, 0, currVal_5, currVal_6); var currVal_7 = _v.context.ngIf; var currVal_8 = _co.questionIndex; var currVal_9 = !_co.hasAnswers; var currVal_10 = "See results"; _ck(_v, 9, 0, currVal_7, currVal_8, currVal_9, currVal_10); }, null); }
function View_QuestionnairePage_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "svi-page-content", [], null, null, null, i10.View_PageContentComponent_0, i10.RenderType_PageContentComponent)), i1.ɵdid(1, 49152, null, 0, i11.PageContentComponent, [], null, null), (_l()(), i1.ɵeld(2, 0, null, 0, 1, "svi-loading", [], null, null, null, i12.View_LoadingComponent_0, i12.RenderType_LoadingComponent)), i1.ɵdid(3, 49152, null, 0, i13.LoadingComponent, [], null, null)], null, null); }
export function View_QuestionnairePage_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "svi-navbar", [], null, null, null, i14.View_NavbarComponent_0, i14.RenderType_NavbarComponent)), i1.ɵdid(1, 49152, null, 0, i15.NavbarComponent, [i16.Location, i17.Router, i18.BrowserStorage], null, null), (_l()(), i1.ɵeld(2, 0, null, null, 4, "svi-page-title", [], null, null, null, i19.View_PageTitleComponent_0, i19.RenderType_PageTitleComponent)), i1.ɵdid(3, 49152, null, 0, i20.PageTitleComponent, [], null, null), (_l()(), i1.ɵeld(4, 0, null, 0, 1, null, null, null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Statement"])), (_l()(), i1.ɵted(6, 0, [" ", "\n"])), (_l()(), i1.ɵand(16777216, null, null, 2, null, View_QuestionnairePage_1)), i1.ɵdid(8, 16384, null, 0, i16.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"], ngIfElse: [1, "ngIfElse"] }, null), i1.ɵpid(131072, i16.AsyncPipe, [i1.ChangeDetectorRef]), (_l()(), i1.ɵand(0, [["loading", 2]], null, 0, null, View_QuestionnairePage_2))], function (_ck, _v) { var _co = _v.component; var currVal_1 = i1.ɵunv(_v, 8, 0, i1.ɵnov(_v, 9).transform(_co.questions)); var currVal_2 = i1.ɵnov(_v, 10); _ck(_v, 8, 0, currVal_1, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.progress; _ck(_v, 6, 0, currVal_0); }); }
export function View_QuestionnairePage_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "svi-questionnaire-page", [], null, null, null, View_QuestionnairePage_0, RenderType_QuestionnairePage)), i1.ɵdid(1, 114688, null, 0, i21.QuestionnairePage, [i17.ActivatedRoute, i17.Router, i22.Apollo, i23.AnswerService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var QuestionnairePageNgFactory = i1.ɵccf("svi-questionnaire-page", i21.QuestionnairePage, View_QuestionnairePage_Host_0, {}, {}, []);
export { QuestionnairePageNgFactory as QuestionnairePageNgFactory };
