<svi-navbar backLabel="Back to questionnaire" i18n-backLabel backRoute="/matching"></svi-navbar>
<svi-page-title i18n>
  Your Individualized Results
</svi-page-title>

<!-- Placeholder -->
<ng-template #placeholder>
  <svi-tab-group *ngIf="!loading" class="svi-matching-results" [(selectedIndex)]="tabIndex" [isPlaceholder]="true">
    <svi-tab label="Ranking" i18n-label>
        <svi-list-item *ngFor="let item of [1,2,3,4]; let i = index" [isPlaceholder]="true" [matchingValue]="0" [class.inactive]="true" [class.placeholder]="true">
        </svi-list-item>
    </svi-tab>
    <svi-tab label="smartmap">
    </svi-tab>
  </svi-tab-group>
</ng-template>


<ng-template #resultsActions let-sharingInfo="sharingInfo">
  <div class="actions">
    <button
      sviSharingTrigger
      [sharingInfo]="sharingInfo"
      class="button d-none d-sm-inline-block"
      (click)="trackSharingTrigger()"
    >
      <i class="fa fa-share-alt"></i>
      <ng-container i18n>Share Results</ng-container>
    </button>
    <button
      sviSharingTrigger
      [sharingInfo]="sharingInfo"
      class="button button-icon d-inline-block d-sm-none"
    >
      <i class="fa fa-share-alt"></i>
    </button>
  </div>
</ng-template>

<ng-template #notEnoughAnswersMessage i18n>
    You must answer at least 15 of the first 16 questions.
</ng-template>

<svi-page-content>
  <div  class="top-panel">
    <div class="filtergroup">
      <svi-filter-group
      [disabled]="loading"
      [elections]="elections | async"
      [state]="filterGroupStateChanges | async"
      (stateChanged)="onFilterGroupChanged($event)"
      ></svi-filter-group>
    </div>
    <button
    class="button votematch"
    (click)="onVotematchClick()"
    [class.warning]="!enoughAnswers"
    >
      <ng-container *ngIf="enoughAnswers; else notEnoughAnswersMessage" i18n>
          See your results for all EU countries<br><b>Go to VoteMatch.eu</b>
      </ng-container>
    </button>
  </div>
</svi-page-content>

<svi-loading *ngIf="loading"></svi-loading>

<ng-container  *ngIf="recommendation | async as recommendation; else placeholder">
<ng-container *ngIf="!loading">
  <svi-tab-group class="svi-matching-results" (selectedTabChanged)="onTabChanged($event)" [(selectedIndex)]="tabIndex">
    <svi-tab label="Ranking" i18n-label>
      <!-- Sharing Info, set meta
        tags only if this tab is active -->
      <ng-container
        #sharingInfo="sviSharingInfo"
        sviSharingInfo
        [setMetaTags]="tabIndex === 0"
        title="Luxembourg National Election 2018"
        i18n-title
        description="Here is my voting advice for the Luxembourg National Election 2018"
        i18n-description
        [imageFromElement]="sharingElement"
        [sharePath]="'/' + localeId + '/share/results?tab=0&rid=' + recommendation.id"
      ></ng-container>

      <ng-container
        *ngTemplateOutlet="resultsActions; context: { sharingInfo: sharingInfo }"
      ></ng-container>
      <div #sharingElement="sviSharingElement" sviSharingElement>
        <svi-list-item
          *ngFor="let matching of recommendation.matchings; let i = index"
          [class.lastseat]="
            recommendation.options.responderType === 'Candidate' && i === nofSeats - 1
          "
          [class.inactive]="
            recommendation.options.responderType === 'Candidate' && i > nofSeats - 1
          "
          [photoUrl]="matching.responder ? matching.responder.photoUrl : null"
          [rank]="i + 1"
          [title]="matching.title"
          [description]="matching.description"
          [matchingValue]="matching.matchingValue"
          (click)="onMatchingSelected(matching)"
        >
        </svi-list-item>
      </div>
    </svi-tab>
    <svi-tab label="smartmap">
      <!-- Sharing Info, set meta
        tags only if this tab is active -->
      <ng-container
        #sharingInfo2="sviSharingInfo"
        sviSharingInfo
        [setMetaTags]="tabIndex === 1"
        title="Luxembourg National Election 2018"
        i18n-title
        description="Here is my smartmap for the Luxembourg National Election 2018"
        i18n-description
        [imageFromElement]="sharingElement2"
        [sharePath]="'/' + localeId + '/share/results?tab=1&rid=' + recommendation.id"
      ></ng-container>
      <ng-container
        *ngTemplateOutlet="resultsActions; context: { sharingInfo: sharingInfo2 }"
      ></ng-container>
      <div class="smartmap">
        <div>
          <label class="checkbox-container"
            ><ng-container i18n>Show my smartmap position</ng-container>
            <input [(ngModel)]="showMySmartmapPosition" type="checkbox" checked="checked" />
            <span class="checkmark"></span>
          </label>
        </div>

        <div *ngIf="(elections | async) as elections">
          <sv-smartmap
            #sharingElement2="sviSharingElement"
            [class.hide-me]="!showMySmartmapPosition"
            sviSharingElement
            [screenshotHeight]="560"
            [positions]="recommendation.positions"
            [xAxisLabels]="[elections[0].dimensions[0].pole1, elections[0].dimensions[0].pole2]"
            [yAxisLabels]="[elections[0].dimensions[1].pole2, elections[0].dimensions[1].pole1]"
            (onSelect)="onSmartmapItemSelect($event)"
          ></sv-smartmap>
        </div>
        <ul class="smartmap-legend">
          <li *ngIf="recommendation.voter && showMySmartmapPosition">
            <svi-chart-legend-item
              label="My position"
              i18n-label
              color="#000000"
            ></svi-chart-legend-item>
          </li>
          <li *ngFor="let item of recommendation.smartmapLegendItems">
            <svi-chart-legend-item
              [label]="item.label"
              [color]="item.color"
            ></svi-chart-legend-item>
          </li>
        </ul>
        <div class="more-info-placeholder"></div>
        <cdk-accordion>
          <svi-accordion-item
            title="More info about Smartmap"
            i18n-title
            [expanded]="infoTextExpanded"
          >
            <div class="info-panel">
              <p class="hint">
                <ng-container i18n
                  >This is a graphical visualisation based on the questions. Each dimension
                  represents ca. 20 questions. Some questions have been assigned to both dimensions
                  and some questions have not been assigned to any. In order to find out which
                  questions are assigned with which axes see the
                </ng-container>
              </p>
              <p class="hint" i18n>
                More European integration: You agree with the voluntary transfer of a part of the
                States national sovereignty to the institutions of the European Union. (The EU has
                more power.)<br />
                Less European integration: You agree to the voluntary transfer of a part of the
                States national sovereignty to the institutions of the European Union. (The EU has
                less power.)
              </p>
              <p class="hint" i18n>
                Left: The state intervenes in the redistribution of wealth. (Nationalized)<br />
                Right: The market regulates the economy and the state does not intervene.
                (Privatized)
              </p>
              <p class="hint" i18n>
                For more information on the smartmap and which statements are assigned with which
                axes, see the <a class="methodology-link" target="_blank" routerLink="/methodology">methodology</a>.
              </p>
            </div>
          </svi-accordion-item>
        </cdk-accordion>
      </div>
    </svi-tab>
  </svi-tab-group>
  <div class="svi-matching-result-summary">
    <!-- Commented out for eu-lux election because there is only one district
    <div class="item">
      <ng-container i18n>District</ng-container>: {{resultSummary.district}}
    </div>
    -->
    <div class="item">
      <ng-container i18n>No. of seats</ng-container>: {{ resultSummary.nofSeats }}
    </div>
    <div class="item">
      <ng-container i18n>No. of candidates</ng-container>: {{ resultSummary.nofCandidates }}
    </div>
    <!-- <div class="item">
      <ng-container i18n>No. of participating candidates</ng-container>: {{resultSummary.nofParticipatingCandidates}}
    </div> -->
  </div>
</ng-container>
</ng-container>

<ng-template #translations>
  <span id="no-information" i18n>No information</span>
  <span id="no-answers" i18n>No answers</span>
  <span id="my-position" i18n>My position</span>
</ng-template>
