import { Component, Input, EventEmitter, Output, OnDestroy } from '@angular/core'
import { District, Party } from '@smartvote/common'
import { FormGroup, FormBuilder } from '@angular/forms'
import { Subscription } from 'rxjs'
import { TrackingService } from '../../core/tracking.service'

export class FilterGroupState {
  district = ''
  name = ''
  party = ''
  incumbent = ''
  elected = ''
  gender = ''
}

@Component({
  selector: 'svi-database-filter-group',
  templateUrl: './database-filter-group.component.html',
  styleUrls: ['./database-filter-group.component.scss']
})
export class DatabaseFilterGroupComponent implements OnDestroy {
  @Input()
  set disabled(value: boolean) {
    this._disabled = value
    if (value) {
      this.form.disable({ emitEvent: false })
    } else {
      this.form.enable({ emitEvent: false })
    }
  }
  get disabled() {
    return this._disabled
  }
  private _disabled = false

  @Input()
  set state(value: FilterGroupState) {
    this._setState(value)
  }
  get state() {
    return this._state
  }
  @Output()
  search = new EventEmitter<FilterGroupState>()
  @Output()
  reset = new EventEmitter()
  @Input()
  districts: District[]
  @Input()
  parties: Party[]
  private _sub: Subscription

  form: FormGroup
  private _state: FilterGroupState

  constructor(fb: FormBuilder, private trackingService: TrackingService) {
    this._state = new FilterGroupState()
    this.form = fb.group(this._state)
    this._sub = this.form.valueChanges.subscribe(state => {
      this._state = { ...this.state, ...state }
      if (JSON.stringify(this._state) !== JSON.stringify(new FilterGroupState())) {
        this.form.markAsDirty()
      } else {
        this.form.markAsPristine()
      }
    })
  }

  ngOnDestroy() {
    this._sub.unsubscribe()
  }

  onSearch() {
    this.trackingService.trackEvent('Profile', 'Search', JSON.stringify(this.form.value))
    this.form.markAsDirty()
    this.search.emit(this.state)
  }

  onReset() {
    this._setState()
    this.reset.emit()
    this.form.markAsPristine()
  }

  private _setState(state: FilterGroupState = new FilterGroupState()) {
    this._state = state
    if (JSON.stringify(this._state) !== JSON.stringify(new FilterGroupState())) {
      this.form.markAsDirty()
    } else {
      this.form.markAsPristine()
    }
    this.form.patchValue(this._state, { emitEvent: false })
  }
}
