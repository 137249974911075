import { Component, AfterViewInit, Inject, PLATFORM_ID } from '@angular/core'
import { Observable } from 'apollo-link'
import { Candidate, Election, Question, Voter } from '@smartvote/common'
import { ActivatedRoute, Router } from '@angular/router'
import { switchMap, map } from 'rxjs/operators'
import { Apollo } from 'apollo-angular'

import { CandidateDetailsQuery, CandidateDetailsWithVoterQuery } from '../../__generated__/types'
import { combineLatest } from 'rxjs'
import { VoterIdService } from '../core/voter-id.service'
import { isPlatformBrowser } from '@angular/common'
const {
  CandidateDetailsWithVoter,
  CandidateDetails
} = require('graphql-tag/loader!./details.page.graphql')

export interface CandidateDetails {
  candidate: Candidate
  election: Election
  questions: Question[]
  voter: Voter
}
@Component({
  selector: 'svi-candidate-details',
  templateUrl: 'candidate-details.page.html'
})
export class CandidateDetailsPage implements AfterViewInit {
  data: Observable<CandidateDetails>
  tabIndex = 0
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    voterIdService: VoterIdService,
    private apollo: Apollo,
    @Inject(PLATFORM_ID) private platformId
  ) {
    this.data = combineLatest(route.params, route.queryParams).pipe(
      switchMap(([params, queryParams]) => {
        this.tabIndex = parseInt(queryParams['tab'], 10) || 0
        if (queryParams.rid) {
          return this._getQueryWithVoter(params.id, queryParams.rid)
        } else {
          return this._getQueryWithoutVoter(params.id)
        }
      }),
      map(({ data }) => {
        const { candidate, election, questionnaire, recommendation } = data as any
        // Deep copy hack for issue: https://github.com/apollographql/apollo-angular/issues/329
        if (recommendation) {
          return candidate
            ? {
                candidate: { ...candidate, smartspider: { ...candidate.smartspider, options: {} } },
                election: { ...election },
                questions: [...questionnaire.questions],
                voter: {
                  ...recommendation.voter,
                  isMe: voterIdService.getVoterId() === recommendation.voter.id
                }
              }
            : {}
        } else {
          return candidate
            ? {
                candidate: { ...candidate, smartspider: { ...candidate.smartspider, options: {} } },
                election: { ...election },
                questions: [...questionnaire.questions]
              }
            : {}
        }
      })
    ) as any
  }

  ngAfterViewInit() {
    if (isPlatformBrowser(this.platformId)) {
      window.scroll(0, 0)
    }
  }

  onTabChanged(index: number) {
    this._updateQueryParams({ tab: index })
  }

  private _updateQueryParams(params) {
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: params,
      replaceUrl: true,
      queryParamsHandling: 'merge'
    })
  }

  private _getQueryWithVoter(candidateId: string, recommendationId: string, electionId = '1') {
    return this.apollo.query<CandidateDetailsWithVoterQuery>({
      query: CandidateDetailsWithVoter,
      variables: { candidateId, electionId, recommendationId }
    })
  }

  private _getQueryWithoutVoter(candidateId, electionId = '1') {
    return this.apollo.query<CandidateDetailsQuery>({
      query: CandidateDetails,
      variables: { candidateId, electionId }
    })
  }
}
