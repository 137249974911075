import { Component, Output, EventEmitter, OnInit } from '@angular/core'

const INITIAL_REMAINING_SECONDS = 30

export interface Language {
  labelCode: string
  code: string
  lang: string
}

@Component({
  selector: 'svi-demo-reset-layover',
  templateUrl: './demo-reset-layover.component.html',
  styleUrls: ['./demo-reset-layover.component.scss']
})
export class DemoResetLayoverComponent implements OnInit {
  @Output() close = new EventEmitter()
  @Output() reset = new EventEmitter()
  remainingSeconds = INITIAL_REMAINING_SECONDS
  interval

  constructor() {}

  ngOnInit() {
    this.startTimer()
  }

  cancel() {
    clearInterval(this.interval)
    this.close.emit()
  }

  resetNow() {
    clearInterval(this.interval)
    this.reset.emit()
  }

  startTimer() {
    this.interval = setInterval(() => {
      if (this.remainingSeconds > 0) {
        this.remainingSeconds--
      } else {
        this.resetNow()
      }
    }, 1000)
  }
}
