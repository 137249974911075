<!-- <svi-navbar></svi-navbar> -->
<div style="display:block;height:40px;"></div>
<svi-page-title i18n> Terms and privacy </svi-page-title>
<svi-page-content>
  <p i18n>
    Smartwielen uses cookies and the local storage of your web browser to improve performance and
    usability. Using smartwielen involves the processing of sensitive data (e.g., political
    positions of candidates and users), therefore data and privacy protection according the EU
    General Data Protection Regulation (GDPR) is a priority.
  </p>
  <p i18n>
    The document below contains a detailed description of our privacy policy as well as the general
    terms of use for users. We strongly encourage everyone to carefully read this document.
  </p>
  <a href="/assets/pdf/privacy_policy_users_fr.pdf" target="_blank" (click)="trackViewTerms()">
    <i class="fa fa-angle-right"></i>
    <ng-container i18n
      >Privacy policy and terms of use for users (PDF, only available in French)</ng-container
    >
  </a>
</svi-page-content>
