import { Component, HostListener, OnInit, Inject, LOCALE_ID } from '@angular/core'
import { AnswerService } from './core/answer.service'
import { TrackingService } from './core/tracking.service'
import { environment } from '../environments/environment'
import { BrowserStorage } from './core/tokens'

const ALLOWED_INACTIVE_SECONDS = 90

@Component({
  selector: 'svi-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  showResetLayover = false
  remainingInactiveSeconds
  countdownTimer
  isDemo: boolean = environment.demo

  constructor(
    private answerService: AnswerService,
    private trackingService: TrackingService,
    @Inject(BrowserStorage) private browserStorage: Storage,
    @Inject(LOCALE_ID) private localeId
  ) {}

  ngOnInit() {
    if (this.isDemo) {
      this.startCountdown()
    }
  }

  get showResetButton(): boolean {
    return !this.showResetLayover && !!this.answerService.getAnswers().length
  }

  startTracking() {
    this.trackingService.init()
    this.trackingService.trackAllPageViews()
  }

  // for demo mode only
  reset() {
    this.browserStorage.clear()
    // reload app
    window.location.replace('')
  }

  startCountdown() {
    clearTimeout(this.countdownTimer)
    this.remainingInactiveSeconds = ALLOWED_INACTIVE_SECONDS
    this.countdown()
  }

  countdown() {
    this.countdownTimer = setTimeout(() => {
      if (!this.showResetButton) {
        // nothing to reset => exit countdown
        return
      }
      if (this.remainingInactiveSeconds > 0) {
        this.remainingInactiveSeconds--
        // go on
        this.countdown()
      } else {
        this.showResetLayover = true
      }
    }, 1000)
  }

  // basic activity detection
  @HostListener('click', ['$event.target'])
  @HostListener('touchstart', ['$event'])
  @HostListener('touchend', ['$event'])
  @HostListener('touchcancel', ['$event'])
  onActivity() {
    if (this.isDemo && this.showResetButton) {
      this.startCountdown()
    }
  }
}
