<div class="svi-profile">
  <svi-profile-photo class="photo" [photoUrl]="responder.photoUrl"></svi-profile-photo>
  <div class="content profile-header">
    <div class="name">{{ displayName }}</div>
    <div
      *ngIf="
        getValue(responder, 'website') ||
        getValue(responder, 'twitter') ||
        getValue(responder, 'facebook') ||
        getValue(responder, 'youtube')
      "
      class="social"
    >
      <span class="social-links-label d-none d-sm-inline" i18n>Web profiles:</span>
      <div class="social-links">
        <a
          rel="external"
          target="_blank"
          *ngIf="getValue(responder, 'website') as url"
          [href]="getTrustedUrl(url)"
        >
          <i class="fa fa-globe"></i>
        </a>
        <a
          rel="external"
          target="_blank"
          *ngIf="getValue(responder, 'twitter') as url"
          [href]="getTrustedUrl(url)"
        >
          <i class="fab fa-twitter"></i>
        </a>
        <a
          rel="external"
          target="_blank"
          *ngIf="getValue(responder, 'facebook') as url"
          [href]="getTrustedUrl(url)"
        >
          <i class="fab fa-facebook-square"></i>
        </a>
        <a
          rel="external"
          target="_blank"
          *ngIf="getValue(responder, 'youtube') as url"
          [href]="getTrustedUrl(url)"
        >
          <i class="fab fa-youtube"></i>
        </a>
      </div>
    </div>
  </div>
  <div class="content profile-details">
    <ng-content></ng-content>
  </div>
</div>
<div *ngIf="getValue(responder, 'statement')" class="svi-statement">
  <span class="profile-detail-label" i18n>Statement:</span>
  <div>{{ getValue(responder, 'statement') }}</div>
</div>

<svi-tab-group
  *ngIf="responder.answers.length > 0"
  class="tabs"
  (selectedTabChanged)="selectedTabChanged.emit($event)"
  [(selectedIndex)]="tabIndex"
>
  <!-- Sharing info -->
  <ng-container
    #sharingInfo2="sviSharingInfo"
    sviSharingInfo
    title="Luxembourg National Election 2018"
    i18n-title
    description="Here is my smartspider compared with the smarspider of {{ displayName }}"
    i18n-description
    [imageFromElement]="sharingElement"
    [sharePath]="sharePath"
  ></ng-container>
  <svi-tab label="Smartspider" i18n-label>
    <div class="button-group">
      <label class="checkbox-container"
        ><ng-container i18n>Show my smartspider</ng-container>
        <input [(ngModel)]="showMySmartspider" type="checkbox" checked="checked" />
        <span class="checkmark"></span>
      </label>
      <button
        *ngIf="voter.isMe"
        sviSharingTrigger
        [sharingInfo]="sharingInfo2"
        class="button button-share"
      >
        <i class="fa fa-share"></i>
        <ng-container i18n>Share smartspider</ng-container>
      </button>
    </div>
    <sv-smartspider
      #sharingElement="sviSharingElement"
      sviSharingElement
      [screenshotHeight]="650"
      class="smartspider"
      [smartspiders]="
        voter && showMySmartspider
          ? [responder.smartspider, voter.smartspider]
          : [responder.smartspider]
      "
    ></sv-smartspider>
    <div class="smartspider-legend">
      <svi-chart-legend-item
        class="legend-item"
        [color]="'#' + responderColor"
        [label]="displayName"
      ></svi-chart-legend-item>
      <svi-chart-legend-item
        *ngIf="showMySmartspider"
        class="legend-item"
        label="My smartspider"
        i18n-label
      ></svi-chart-legend-item>
    </div>
    <div class="more-info-placeholder"></div>
    <cdk-accordion>
      <svi-accordion-item
        title="More info about Smartspider"
        i18n-title
        [expanded]="infoTextExpanded"
      >
        <div class="info-panel">
          <p class="hint">
            <ng-container i18n
              >This is a graphical visualisation based on the statements. Each axis represents 2-4
              statements. Some statements have been assigned to multiple axes and some statements
              have not been assigned to any axes. In order to find out which questions are assigned
              with which axes see the
            </ng-container>
          </p>
          <p class="hint" i18n>
            Each axis goes from 0 to 100. <br />
            100 = a very high adherence to the goal <br />
            0 = no adhesion to the goal<br />
          </p>
          <p class="hint" i18n>
            But what do the different axes/goals mean?
          </p>
          <p class="hint" i18n>
            Society: You agree with the fundamental rights such as freedom of expression, freedom of
            the press, but also the fight against discrimination on grounds of ethnicity, sex,
            nationality, religion or sexual orientation.
          </p>
          <p class="hint" i18n>
            Economic Liberalism: You agree that the state does not interfere in the economic areas.
            The market regulates itself over the demand and the offer. Ownership, freedom of
            ownership and growth are the essential engines to ensure the balance of the economy.
          </p>
          <p class="hint" i18n>
            National sovereignty: You agree that the EU Member States should maintain their autonomy
            and independence. This goal underlines the importance of the identity and cultures of
            the nations.
          </p>
          <p class="hint" i18n>
            Welfare state: You agree that in order to guarantee equality between human beings it is
            essential that the state regulates the economy. Public policies put into practice by the
            welfare state support and regulate in the fields of culture, education, employment,
            training, health, work and the economy.
          </p>
          <p class="hint" i18n>
            Environmental Protection: You agree that cultural, economic and social development must
            respect the nature. It is more than a preservation of the nature because it strives for
            a "sustainable" development.
          </p>
          <p class="hint" i18n>
            For more information on the smartspider and which statements are assigned with which
            axes, see the
            <a class="methodology-link" target="_blank" routerLink="/methodology">methodology</a>.
          </p>
        </div>
      </svi-accordion-item>
    </cdk-accordion>
  </svi-tab>
  <svi-tab label="Questionnaire" i18n-label>
    <div class="answer-option-selection-header">
      <span class="header-item" *ngIf="voter && voter.isMe">
        <i class="fa fa-user-circle"></i>&nbsp;
        <ng-container i18n>Me</ng-container>
      </span>
      <svi-chart-legend-item
        class="header-item"
        [color]="'#' + responderColor"
        [label]="displayName"
      ></svi-chart-legend-item>
    </div>
    <cdk-accordion multi="true">
      <svi-accordion-item
        *ngFor="let category of categories; first as isFirstCategory"
        [title]="category"
        [expanded]="isFirstCategory"
      >
        <svi-question-item
          class="svi-question-item"
          [class.active]="i % 2 === 0"
          *ngFor="let qItem of questionItems[category]; first as isFirst; let i = index"
          [text]="qItem.text"
          [comment]="qItem.comment"
          [index]="qItem.index"
        >
          <svi-answer-option-group class="answer-option-group">
            <svi-answer-option-item
              [selections]="qItem.answerSelections['100']"
              label="Yes"
              i18n-label
              [showLabel]="isFirst"
              [color]="'#' + responderColor"
            ></svi-answer-option-item>
            <svi-answer-option-item
              [selections]="qItem.answerSelections['75']"
              label="Rather yes"
              i18n-label
              [showLabel]="isFirst"
              [color]="'#' + responderColor"
            ></svi-answer-option-item>
            <svi-answer-option-item
              [selections]="qItem.answerSelections['25']"
              label="Rather no"
              i18n-label
              [showLabel]="isFirst"
              [color]="'#' + responderColor"
            ></svi-answer-option-item>
            <svi-answer-option-item
              [selections]="qItem.answerSelections['0']"
              label="No"
              i18n-label
              [showLabel]="isFirst"
              [color]="'#' + responderColor"
            ></svi-answer-option-item>
          </svi-answer-option-group>
        </svi-question-item>
      </svi-accordion-item>
    </cdk-accordion>
  </svi-tab>
</svi-tab-group>
