<!-- <svi-navbar></svi-navbar> -->
<div style="display:block;height:40px;"></div>
<svi-page-title i18n> Frequently Asked Questions (FAQ) </svi-page-title>
<svi-page-content>
  <h3 i18n>What is smartwielen?</h3>
  <p>
      <ng-container i18n>
        smartwielen is a questionnaire-based tool that allows users to compare their own political views with those of candidates and parties running in the European elections 2019 in Luxembourg. smartwielen provides users with first-hand information about the candidates and parties. It enables users not only to reflect on their own political positions, but also to learn more about the political programmes of political parties and candidates. Furthermore, it calculates which candidates and parties are the closest matches to the user. The questionnaire consists of 33 statements that cover a broad spectrum of political issues. The statements are answered by the candidates/parties, as well as the users. The matching result is based on the 33 statements and shows the similarities and differences between the candidates/parties and the users. It is important to note, that the goal of smartwielen is not to encourage users to vote in a certain way, but rather to invite them to seek out more information. To learn more about how smartwielen works, watch our 
      </ng-container>
      <a [href]="videoUrl" class="inline-link" i18n>short video.</a>
  </p>

  <h3 i18n>How was the questionnaire developed?</h3>
  <p i18n>The main criteria for the selection of questions are:</p>
  <ul>
    <li i18n>they are concerned with the current political issues in Luxembourg and Europe;</li>
    <li i18n>
      they originate from the programs of political parties and/or from consultations with
      journalists, civil society and interests;
    </li>
    <li i18n>they do not favor one or more political party(s);</li>
    <li i18n>they do not allow for multiple interpretations;</li>
    <li i18n>
      Sie haben einen allgemeinen oder spezifischen Charakter (eine Mischung beider Arten wird
      angestrebt); Platzhalter
    </li>
    <li i18n>they deal with only one subject;</li>
    <li>
      <ng-container i18n>
          they fit the design of the smartspider. For more information, see the 
      </ng-container>
      <a (click)="navigateToMethodology()" class="inline-link" i18n>
        methodology.
      </a>
    </li>
  </ul>

  <h3 i18n>How are the candidates’ and parties’ positions determined?</h3>
  <p>
    <ng-container i18n>
      The candidates and parties answer the smartwielen questionnaire, which includes 33 statements on a wide range of current policy issues, some weeks before the election. By doing so they generate their political profile at 
    </ng-container>
    <a href="www.smartwielen.lu" class="inline-link" i18n>
      smartwielen.lu.
    </a>
  </p>

  <h3 i18n>How are the results calculated?</h3>
  <p>
    <ng-container i18n>
      On the smartwielen website users answer the exact same questionnaire that candidates and parties have already answered. smartwielen compares their answers with those of the candidates/parties by calculating the euclidean distance (the straight-line distance between two-points in a multidimensional space). As a result, smartwielen presents to each voter a specific ranking of all participating candidates/parties, ranking those candidates/parties with the best matching at the top of the list. The more statements a user answers, the more precise their matching will be. For more information, see the 
    </ng-container>
    <a (click)="navigateToMethodology()" class="inline-link" i18n>
        methodology.
    </a>
  </p>

  <h3 i18n>How should I interpret the matching score?</h3>
  <p>
    <ng-container i18n>
      The results are based on a geometric matching. Therefore, the value cannot be considered directly as a proportion of the statements in which a voter is absolutely in agreement with the answers of a candidate. Therefore, a match of 70% does not mean that a candidate/party has given exactly the same answer as the voter for exactly 70% of the statements. For more information, see the 
    </ng-container>
    <a (click)="navigateToMethodology()" class="inline-link" i18n>
        methodology.
    </a>
  </p>

  <h3 i18n>How does weighting answers effect my results?</h3>
  <p>
      <ng-container i18n>
        Users have the opportunity to weight their answers, which will be considered when calculating their results. Weighting statements enable their results to reflect which issues they care about most or least. If a user gives a statement a plus sign, then it the answer counts twice in the calculation of the matching results (+ = 2). If a user gives a statement a minus sign, it counts as if the answer were half as important (- = 0.5). For more information, see the 
      </ng-container>
      <a (click)="navigateToMethodology()" class="inline-link" i18n>
          methodology.
      </a>
  </p>

  <h3 i18n>I filled out smartwielen a few weeks ago, how can I find my old results?</h3>
  <p>
      <ng-container i18n>
        The answers that a user gives are saved on the computer's session storage. As soon as a user closes the tab/browser window or turns off their computer the session ends and all answers will be deleted. We do this to ensure that your data is not accessed by other users of the same device. If you would like to save your results for later reference, there is the option to send your results via email on the results page. For more information, see the 
      </ng-container>
      <a (click)="navigateToMethodology()" class="inline-link" i18n>
        methodology.
      </a>
  </p>

  <h3 i18n>How can I interpret the smartspider? How is the smartspider calculated?</h3>
  <p>
      <ng-container i18n>
        The smartspider is a graphical visualisation of the political profiles of the candidates/parties and user based on their answers to the questionnaire. Each of the six axes represents a formulated goal in a policy area (e.g. Environmental Protection) based on 2-4 statements from the questionnaire. Some statements have been assigned to multiple axes and some statements have not been assigned to any axes. For each axis a value of between 100 and 0 points can be reached. A value of 100, at the outside edge of the spider, represents strong agreement with the formulated goal of the corresponding axis. A value of 0, at the middle of the spider, means that there is no agreement with the formulated goal. The more similar the political profiles of a candidate/party and user are, the more their smartspiders will overlap. For more information on the smartspider and which statements are assigned with which axes, see the 
      </ng-container>
      <a (click)="navigateToMethodology()" class="inline-link" i18n>
          methodology.
      </a>
  </p>

  <h3 i18n>How can I interpret the smartmap? How is the smartmap calculated?</h3>
  <p>
      <ng-container i18n>
        The smartmap is a graphical visualisation of the political profiles of the candidates/parties and user based on their answers to the questionnaire. Each of the two axes represents ca. 20 statements from the questionnaire. Some statements have been assigned to both axes and some statements have not been assigned to either. The more similar the political profiles of a candidate/party and user are, the closer the points marking their positions on the smartmap will be. The horizontal axis represents the classic economic left–right dimension, which focuses on the role of government intervention in the economy. The horizontal axis represents the classic economic left–right dimension, which focuses on the role of government intervention in the economy. The vertical axis represents the European integration dimension, which focuses on the level of economic and political harmonization of the European Union. For more information on the smartmap and which statements are assigned with which axes, see the 
      </ng-container>
      <a (click)="navigateToMethodology()" class="inline-link" i18n>
          methodology.
      </a>
  </p>

  <h3 i18n>New question about VoteMatch.eu</h3>
  <p i18n>New answer about VoteMatch.eu</p>

  <h3 i18n>
    Why are some candidates and parties not included in the platform? Why are there no answers for
    some parties and candidates?
  </h3>
  <p i18n>
    We contacted all parties and candidates (via the parties) ahead of the launching of the tool and
    asked if they were interested in participating. All parties and candidates that agreed are
    included in the website. However, not all parties and candidates have completed and confirmed
    the questionnaire.
  </p>

  <h3 i18n>What happens to my answers in the user survey?</h3>
  <p>
    <ng-container i18n>
      The answers given in the user survey are anonymous and will not affect the results. They will be used by the University of Luxembourg for research purposes. For more information, please contact 
    </ng-container>
    <a href="mailto:info@smartwielen.lu">info@smartwielen.lu</a>
  </p>

  <h3 i18n>
    Is smartwielen associated with any specific political parties, candidates, special interests,
    etc.?
  </h3>
  <p i18n>
    No, all organisations involved in the development and operation of smartwielen (e.g., the
    University of Luxembourg, Zentrum fir politesch Bildung and smartvote/Politools) are
    non-partisan and non-profit organizations.
  </p>

  <h3 i18n>
    What is VoteMatch Europe??
  </h3>
  <p>
    <ng-container i18n
      >The VoteMatch Europe developed a questionnaire with 16 questions for all the partners of the
      project. These represent the first 16 statements of the smartwielen questionnaire. Upon
      recieving your results on smartwielen, you can switch to VoteMatch Europe. Your answers to the
      first 16 questions will be transferred at the same time and you can see your matching with all
      the different parties in Europe. If you choose not to participate and press the VoteMatch
      Europe button, no data will be forwarded. If you have any questions about this project, please
      contact us at
    </ng-container>
    <a href="mailto:info@smartwielen.lu">info@smartwielen.lu</a>
    <ng-container i18n> or visit </ng-container>
    <a href="" target="_blank">www.votematch.eu</a>.
  </p>

  <h3 i18n>
    How does VoteMatch Europe work?
  </h3>
  <p i18n>
    The VoteMatch Europe network is a network of VAAs (Voting Advice Application). The members of
    the network are all neutral and non-partisan entities/organizations, who have experience either
    in devloping VAAs or in research on VAAs. VoteMatch Europe seeks to implement a European-wide
    VAA for the European Parliamentary Elections 2019. This has the potential to better inform
    citizens about elections for the European Parliament (EP) and to increase transparency on the
    policy positions of the contesting parties.
  </p>
</svi-page-content>
