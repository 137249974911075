<div class="svi-questionnaire-answer">
  <div class="answer-container">
    <div style="width: 100px;" class="d-none d-sm-block"></div>
    <div class="answer-options">
      <button (click)="answerChange(100)" [ngClass]="{ active: userAnswer.value === 100 }" i18n>
        Yes
      </button>
      <button (click)="answerChange(75)" [ngClass]="{ active: userAnswer.value === 75 }" i18n>
        Rather<br />yes
      </button>
      <button
        *ngIf="isStandard5Question"
        (click)="answerChange(50)"
        [ngClass]="{ active: userAnswer.value === 50 }"
        i18n
      >
        Neutral
      </button>
      <button (click)="answerChange(25)" [ngClass]="{ active: userAnswer.value === 25 }" i18n>
        Rather<br />no
      </button>
      <button (click)="answerChange(0)" [ngClass]="{ active: userAnswer.value === 0 }" i18n>
        No
      </button>
    </div>
    <div [class.hidden]="responderType === 'Candidate'" class="no-answer d-none d-sm-block">
      <button (click)="answerChange(-9)" [ngClass]="{ active: userAnswer.value === -9 }" i18n>
        No answer
      </button>
    </div>
  </div>
  <div class="weight-container-outer">
    <div class="weight-container-inner">
      <div class="weight-options">
        <div class="title" i18n>Weight answer!</div>
        <button (click)="weightChange(0.5)" [ngClass]="{ active: userAnswer.weight === 0.5 }">
          <i class="fa fa-minus"></i>
        </button>
        <button (click)="weightChange(1)" [ngClass]="{ active: userAnswer.weight === 1 }">
          <i class="fa fa-equals"></i>
        </button>
        <button (click)="weightChange(2)" [ngClass]="{ active: userAnswer.weight === 2 }">
          <i class="fa fa-plus"></i>
        </button>
      </div>
      <div [class.hidden]="responderType === 'Candidate'" class="no-answer d-block d-sm-none">
        <button (click)="answerChange(-9)" [ngClass]="{ active: userAnswer.value === -9 }" i18n>
          No answer
        </button>
      </div>
    </div>
  </div>
</div>
