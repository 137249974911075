export const MUNICIPALITIES = [
  'Beaufort',
  'Bech',
  'Beckerich',
  'Berdorf',
  'Bertrange',
  'Bettembourg',
  'Bettendorf',
  'Betzdorf',
  'Bissen',
  'Biwer',
  'Boulaide',
  'Bourscheid',
  'Bous',
  'Clervaux',
  'Colmar-Berg',
  'Consdorf',
  'Contern',
  'Dalheim',
  'Diekirch',
  'Differdange',
  'Dippach',
  'Dudelange',
  'Echternach',
  'Ell',
  'Erpeldange-sur-Sûre',
  'Esch-sur-Alzette',
  'Esch-sur-Sûre',
  'Ettelbruck',
  'Feulen',
  'Fischbach',
  'Flaxweiler',
  'Frisange',
  'Garnich',
  'Goesdorf',
  'Grevenmacher',
  'Grosbous',
  'Habscht',
  'Heffingen',
  'Helperknapp',
  'Hesperange',
  'Junglinster',
  'Käerjeng',
  'Kayl',
  'Kehlen',
  'Kiischpelt',
  'Koerich',
  'Kopstal',
  'Lac de la Haute-Sûre',
  'Larochette',
  'Lenningen',
  'Leudelange',
  'Lintgen',
  'Lorentzweiler',
  'Luxembourg',
  'Mamer',
  'Manternach',
  'Mersch',
  'Mertert',
  'Mertzig',
  'Mondercange',
  'Mondorf-les-Bains',
  'Niederanven',
  'Nommern',
  'Parc Hosingen',
  'Pétange',
  'Préizerdaul',
  'Putscheid',
  'Rambrouch',
  'Reckange-sur-Mess',
  'Redange/Attert',
  'Reisdorf',
  'Remich',
  'Roeser',
  'Rosport-Mompach',
  'Rumelange',
  'Saeul',
  'Sandweiler',
  'Sanem',
  'Schengen',
  'Schieren',
  'Schifflange',
  'Schuttrange',
  'Stadtbredimus',
  'Steinfort',
  'Steinsel',
  'Strassen',
  'Tandel',
  'Troisvierges',
  'Useldange',
  'Vallée de l\'Ernz',
  'Vianden',
  'Vichten',
  'Wahl',
  'Waldbillig',
  'Waldbredimus',
  'Walferdange',
  'Weiler-la-Tour',
  'Weiswampach',
  'Wiltz',
  'Wincrange',
  'Winseler',
  'Wormeldange'
]
