import { Component, Input, Output, EventEmitter } from '@angular/core'
import { Answer, QuestionType } from '@smartvote/common'

@Component({
  selector: 'svi-questionnaire-answer',
  templateUrl: './answer.component.html',
  styleUrls: ['./answer.component.scss']
})
export class QuestionnaireAnswerComponent {
  @Input() userAnswer: Answer
  @Input() number: number
  @Input() questionType: QuestionType = QuestionType.StandardQuestion
  @Input() responderType: 'Candidate' | 'Voter' = 'Voter'
  @Output() answerChanged = new EventEmitter()

  answerChange(newValue: number) {
    this.answerChanged.emit(Object.assign(this.userAnswer, { value: newValue }))
  }

  weightChange(newValue: number) {
    this.answerChanged.emit(Object.assign(this.userAnswer, { weight: newValue }))
  }

  get isStandard5Question(): boolean {
    return this.questionType === QuestionType.Standard5Question
  }
}
