/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./demo-reset-layover.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./demo-reset-layover.component";
var styles_DemoResetLayoverComponent = [i0.styles];
var RenderType_DemoResetLayoverComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_DemoResetLayoverComponent, data: {} });
export { RenderType_DemoResetLayoverComponent as RenderType_DemoResetLayoverComponent };
export function View_DemoResetLayoverComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 19, "div", [["class", "demo-reset-layover"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 18, "div", [["class", "demo-reset-layover__inner"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 17, "div", [["class", "content"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "h2", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Ending session"])), (_l()(), i1.ɵeld(5, 0, null, null, 9, "div", [["class", "text"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Select \"End session\" to delete all your data and answers, and return back to the homepage. Select \"Cancel\" to continue with smartwielen. "])), (_l()(), i1.ɵeld(8, 0, null, null, 6, "p", [], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 1, null, null, null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["If you make no choice, the page will be reset in "])), (_l()(), i1.ɵted(11, null, ["", ""])), (_l()(), i1.ɵeld(12, 0, null, null, 1, null, null, null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" seconds"])), (_l()(), i1.ɵted(-1, null, [". "])), (_l()(), i1.ɵeld(15, 0, null, null, 4, "div", [["class", "actions"]], null, null, null, null, null)), (_l()(), i1.ɵeld(16, 0, null, null, 1, "button", [["class", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.cancel() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Cancel"])), (_l()(), i1.ɵeld(18, 0, null, null, 1, "button", [["class", "button button-accept"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.resetNow() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["End session now"]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.remainingSeconds; _ck(_v, 11, 0, currVal_0); }); }
export function View_DemoResetLayoverComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "svi-demo-reset-layover", [], null, null, null, View_DemoResetLayoverComponent_0, RenderType_DemoResetLayoverComponent)), i1.ɵdid(1, 114688, null, 0, i2.DemoResetLayoverComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var DemoResetLayoverComponentNgFactory = i1.ɵccf("svi-demo-reset-layover", i2.DemoResetLayoverComponent, View_DemoResetLayoverComponent_Host_0, {}, { close: "close", reset: "reset" }, []);
export { DemoResetLayoverComponentNgFactory as DemoResetLayoverComponentNgFactory };
