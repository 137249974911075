import { OnInit } from '@angular/core';
import { AnswerService } from './core/answer.service';
import { TrackingService } from './core/tracking.service';
import { environment } from '../environments/environment';
var ALLOWED_INACTIVE_SECONDS = 90;
var AppComponent = /** @class */ (function () {
    function AppComponent(answerService, trackingService, browserStorage, localeId) {
        this.answerService = answerService;
        this.trackingService = trackingService;
        this.browserStorage = browserStorage;
        this.localeId = localeId;
        this.showResetLayover = false;
        this.isDemo = environment.demo;
    }
    AppComponent.prototype.ngOnInit = function () {
        if (this.isDemo) {
            this.startCountdown();
        }
    };
    Object.defineProperty(AppComponent.prototype, "showResetButton", {
        get: function () {
            return !this.showResetLayover && !!this.answerService.getAnswers().length;
        },
        enumerable: true,
        configurable: true
    });
    AppComponent.prototype.startTracking = function () {
        this.trackingService.init();
        this.trackingService.trackAllPageViews();
    };
    // for demo mode only
    AppComponent.prototype.reset = function () {
        this.browserStorage.clear();
        // reload app
        window.location.replace('');
    };
    AppComponent.prototype.startCountdown = function () {
        clearTimeout(this.countdownTimer);
        this.remainingInactiveSeconds = ALLOWED_INACTIVE_SECONDS;
        this.countdown();
    };
    AppComponent.prototype.countdown = function () {
        var _this = this;
        this.countdownTimer = setTimeout(function () {
            if (!_this.showResetButton) {
                // nothing to reset => exit countdown
                return;
            }
            if (_this.remainingInactiveSeconds > 0) {
                _this.remainingInactiveSeconds--;
                // go on
                _this.countdown();
            }
            else {
                _this.showResetLayover = true;
            }
        }, 1000);
    };
    // basic activity detection
    AppComponent.prototype.onActivity = function () {
        if (this.isDemo && this.showResetButton) {
            this.startCountdown();
        }
    };
    return AppComponent;
}());
export { AppComponent };
