/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./terms-and-privacy.page.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../shared/page-title/page-title.component.ngfactory";
import * as i3 from "../../shared/page-title/page-title.component";
import * as i4 from "../../shared/page-content/page-content.component.ngfactory";
import * as i5 from "../../shared/page-content/page-content.component";
import * as i6 from "./terms-and-privacy.page";
import * as i7 from "../../core/tracking.service";
var styles_TermsAndPrivacyPage = [i0.styles];
var RenderType_TermsAndPrivacyPage = i1.ɵcrt({ encapsulation: 0, styles: styles_TermsAndPrivacyPage, data: {} });
export { RenderType_TermsAndPrivacyPage as RenderType_TermsAndPrivacyPage };
export function View_TermsAndPrivacyPage_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "div", [["style", "display:block;height:40px;"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "svi-page-title", [], null, null, null, i2.View_PageTitleComponent_0, i2.RenderType_PageTitleComponent)), i1.ɵdid(2, 49152, null, 0, i3.PageTitleComponent, [], null, null), (_l()(), i1.ɵted(-1, 0, [" Terms and privacy\n"])), (_l()(), i1.ɵeld(4, 0, null, null, 9, "svi-page-content", [], null, null, null, i4.View_PageContentComponent_0, i4.RenderType_PageContentComponent)), i1.ɵdid(5, 49152, null, 0, i5.PageContentComponent, [], null, null), (_l()(), i1.ɵeld(6, 0, null, 0, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["smartwielen uses cookies and the session storage of your web browser to improve performance and usability. Using smartwielen involves the processing of sensitive data (e.g., political positions of candidates and users), therefore data and privacy protection according the EU General Data Protection Regulation (GDPR) is a priority.\n"])), (_l()(), i1.ɵeld(8, 0, null, 0, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["The document below contains a detailed description of our privacy policy as well as the general terms of use for users. We strongly encourage everyone to carefully read this document."])), (_l()(), i1.ɵeld(10, 0, null, 0, 3, "a", [["href", "/assets/pdf/privacy_policy_users_fr.pdf"], ["target", "_blank"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.trackViewTerms() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(11, 0, null, null, 0, "i", [["class", "fa fa-angle-right"]], null, null, null, null, null)), (_l()(), i1.ɵeld(12, 0, null, null, 1, null, null, null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Privacy policy and terms of use for users (PDF, only available in French)"]))], null, null); }
export function View_TermsAndPrivacyPage_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "svi-terms-and-privacy", [], null, null, null, View_TermsAndPrivacyPage_0, RenderType_TermsAndPrivacyPage)), i1.ɵdid(1, 4243456, null, 0, i6.TermsAndPrivacyPage, [i1.PLATFORM_ID, i7.TrackingService], null, null)], null, null); }
var TermsAndPrivacyPageNgFactory = i1.ɵccf("svi-terms-and-privacy", i6.TermsAndPrivacyPage, View_TermsAndPrivacyPage_Host_0, {}, {}, []);
export { TermsAndPrivacyPageNgFactory as TermsAndPrivacyPageNgFactory };
