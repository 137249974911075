import { Component, Inject, PLATFORM_ID, AfterViewInit, LOCALE_ID } from '@angular/core'
import { isPlatformBrowser } from '@angular/common'
import { Router } from '@angular/router'
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser'

const VIDEO_URL = {
  en: 'https://www.youtube.com/embed/58f0IcwHYEk',
  de: 'https://www.youtube.com/embed/zTDEZOptHlw',
  lb: 'https://www.youtube.com/embed/_kXBnEA1bLU',
  fr: 'https://www.youtube.com/embed/EpkH56dSwmE',
  pt: 'https://www.youtube.com/embed/erR1bBjJvZs'
}

@Component({
  selector: 'svi-faq',
  templateUrl: './faq.page.html',
  styleUrls: ['./faq.page.scss']
})
export class FaqPage implements AfterViewInit {
  videoUrl: SafeResourceUrl

  constructor(
    @Inject(PLATFORM_ID) private platformId,
    private router: Router,
    private sanitizer: DomSanitizer,
    @Inject(LOCALE_ID) private localeId
  ) {
    this.videoUrl = this.sanitizer.bypassSecurityTrustResourceUrl(VIDEO_URL[this.localeId])
  }

  ngAfterViewInit() {
    if (isPlatformBrowser(this.platformId)) {
      window.scroll(0, 0)
    }
  }

  navigateToMethodology() {
    this.router.navigate(['/methodology'], { fragment: 'smartmap' })
  }
}
