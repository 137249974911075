import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { RouterModule } from '@angular/router'

import { LayoutComponent } from './layout/layout.component'
import { environment } from '../../environments/environment'
import { FooterComponent } from './footer/footer.component'
import { HeaderComponent } from './header/header.component'
import { PolicyLayoverComponent } from './policy-layover/policy-layover.component'
import { DemoResetLayoverComponent } from './demo-reset-layover/demo-reset-layover.component'
import { LanguageMenuComponent } from './header/language-menu/language-menu.component'
import { LanguageOverlayComponent } from './header/language-menu/language-overlay/language-overlay.component'

import { SharedModule } from '../shared/shared.module'
import { BrowserStorage } from './tokens'
import { AnswerService } from './answer.service'
import { VoterIdService } from './voter-id.service'
import { TrackingService } from './tracking.service'

const COMPONENTS = [
  LayoutComponent,
  FooterComponent,
  HeaderComponent,
  LanguageMenuComponent,
  LanguageOverlayComponent,
  PolicyLayoverComponent,
  DemoResetLayoverComponent
]

@NgModule({
  imports: [CommonModule, RouterModule, SharedModule],
  declarations: COMPONENTS,
  exports: COMPONENTS,
  providers: [
    {
      provide: BrowserStorage,
      useValue:
        typeof window !== 'undefined' ? window.sessionStorage : { setItem() {}, getItem() {} }
    },
    AnswerService,
    VoterIdService,
    TrackingService
  ],
  entryComponents: [LanguageOverlayComponent]
})
export class CoreModule {}
