import {
  Component,
  Inject,
  ViewChild,
  TemplateRef,
  AfterViewInit,
  ElementRef,
  PLATFORM_ID,
  LOCALE_ID
} from '@angular/core'
import { Router, ActivatedRoute } from '@angular/router'
import { Observable, combineLatest, of, BehaviorSubject } from 'rxjs'
import { map, switchMap, tap } from 'rxjs/operators'
import { isPlatformBrowser } from '@angular/common'
import { LastRouteService } from './last-route.service'

import { Apollo } from 'apollo-angular'
import {
  Election,
  Recommendation,
  RecommendationOptions,
  Candidate,
  Party,
  Matching,
  Responder
} from '@smartvote/common'
import { Position } from '@smartvote/components/src/app/smartmap/smartmap.component'

import { AnswerService } from '../core/answer.service'
import { VoterIdService } from '../core/voter-id.service'
import { BrowserStorage } from '../core/tokens'

import {
  GetElectionsQuery,
  GetRecommendationQuery,
  CreateRecommendationMutation
} from '../../__generated__/types'
import { FilterGroupState, stateIsEqual, stateIsValid } from './filter-group/filter-group.component'
import { USER_SURVEY_KEY } from '../questions-about-you/questions-about-you.page'
import { TrackingService } from '../core/tracking.service'

// DECLARES VOTEMATCH NAME WHEN EMBEDDING FROM REMOTE LOCATION
// declare var VotematchEU: any;

const {
  CreateRecommendation,
  GetRecommendation,
  GetElections
} = require('graphql-tag/loader!./matching.page.graphql')

@Component({
  selector: 'svi-matching-page',
  templateUrl: 'matching.page.html',
  styleUrls: ['matching.page.scss']
})
export class MatchingPage implements AfterViewInit {
  @ViewChild('translations')
  translationTemplate: TemplateRef<any>
  translations: any
  tabIndex = 0
  elections: Observable<Election[]>
  recommendation: Observable<Recommendation>
  filterGroupStateChanges: BehaviorSubject<FilterGroupState> = new BehaviorSubject(
    new FilterGroupState()
  )
  resultSummary = {
    district: '',
    nofSeats: '',
    nofCandidates: ''
    // nofParticipatingCandidates: '' // TODO: Figure out how to get this number
  }
  nofSeats: number
  loading = true
  infoTextExpanded = false
  showMySmartmapPosition = true
  // Current states
  private _elections: Election[]
  private _recommendation: Recommendation
  private _filterGroupState: FilterGroupState = new FilterGroupState()

  // VoteMatch
  sendVotematch = false
  enoughAnswers = true
  results = {}
  partyIdMap = {
    '1': 'LU08',
    '2': 'LU06',
    '3': 'LU02',
    '4': 'LU01',
    '5': 'LU03',
    '6': 'LU07',
    '7': 'LU04',
    '8': 'LU05',
    '9': 'LU09',
    '12': 'LU10'
  }
  votematchLanguageMap = {
    'lb': 'LB',
    'de': 'DE',
    'fr': 'FR',
    'en': 'EN',
    'pt': 'EN'
  }

  constructor(
    private apollo: Apollo,
    private router: Router,
    private route: ActivatedRoute,
    private answerService: AnswerService,
    private voterIdService: VoterIdService,
    private trackingService: TrackingService,
    @Inject(BrowserStorage) private browserStorage: Storage,
    @Inject(PLATFORM_ID) private platformId,
    @Inject(LOCALE_ID) public localeId: string,
    private lastRouteSrv: LastRouteService
  ) {
    this.elections = this._getElections() as any // TODO: Fix types
    this.recommendation = combineLatest(
      this.route.queryParams,
      this.filterGroupStateChanges,
      this.elections
    ).pipe(
      switchMap(([params, filterGroupState, elections]) => {
        this._elections = elections
        this.tabIndex = parseInt(params['tab'], 10) || 0
        const recommendationId = params['rid']
        this.loading = true
        // If there is only one election and this election has only one district, autoselect them
        if (elections.length === 1 && elections[0].districts.length === 1) {
          filterGroupState.election = elections[0].id
          filterGroupState.district = elections[0].districts[0].id
        }
        if (this._doCreateRecommendation(filterGroupState)) {
          return this.createRecommendation(filterGroupState)
        } else if (this._doGetRecommendation(recommendationId)) {
          return this._getRecommendation(recommendationId, 0, -1)
        } else if (stateIsValid(filterGroupState)) {
          return of(this._recommendation)
        } else {
          return of(null)
        }
      }),
      tap((recommendation: any) => {
        // TODO(JLO, 03.09.18): redirect to share view if it's not me
        this.loading = false
        if (!recommendation) {
          return
        }
        recommendation.positions = getSmartmapPositions(recommendation).concat({
          id: 'me',
          color: 'red',
          label: this.getTranslatedMessage('my-position'),
          ...recommendation.voter.smartmapPosition
        })
        recommendation.smartmapLegendItems = getSmartmapLegendItems(recommendation)
        recommendation.matchings = recommendation.matchings.map(matching => ({
          ...matching,
          ...this._getListItemLabels(matching)
        }))
        if (!this._recommendation || this._recommendation.id !== recommendation.id) {
          // Keep last state before emiting to avoid infite loop
          this._filterGroupState = this._getFilterGroupState(recommendation.options)
          this._recommendation = recommendation
          this._updateQueryParams({ rid: recommendation.id })
          this._updateResultSummary(this._elections, this._filterGroupState, recommendation)
          this._updateNofSeats(this._elections, this._filterGroupState)
          this.filterGroupStateChanges.next(this._filterGroupState)
        }
        this.browserStorage.setItem('recommendationId', recommendation.id)
        if (this.sendVotematch) {
          this.votematch()
        }
      })
    ) as any
  }

  onVotematchClick() {
    const answers = this.answerService.getAnswers()
    this.results = {}

    // Find the first 16 questions (which have to be the votematch questions!)
    for (let i = 1; i <= 16; i++) {
      const answer = answers.find(a => {
        return a.questionId === i.toString()
      })
      // If an answer is not present, continue
      if (!answer) {
        continue
      }
      // Map the answer's value to the allowed ones for votematch
      let value
      if (answer.value === -9) {
        continue
      } else if (answer.value < 50) {
        value = -1
      } else if (answer.value > 50) {
        value = 1
      }
      // Construct the dictionary key for the current answer
      let key = 'answer'
      if (parseInt(answer.questionId, 10) >= 10) {
        key += answer.questionId
      } else {
        key += '0' + answer.questionId
      }
      // Set the dictionary entry
      this.results[key] = value
    }

    // Check if enough quesions were answered to allow votematch feature.
    // Abort if this is not the case.
    if (Object.keys(this.results).length < 15) {
      this.enoughAnswers = false
      return
    }

    // Set flag
    this.sendVotematch = true
    // Create recommendation for parties, if this is not already the case
    const fg = {...this._filterGroupState }
    fg.responderType = 'Party'
    this.onFilterGroupChanged(fg)
  }

  votematch() {
    this.sendVotematch = false
    VotematchEU.settings({lang: this.votematchLanguageMap[this.localeId]});

    // Get best match(es) that has (have) a votematchId
    const matchings = []
    for (let i = 0; i < this._recommendation.matchings.length; i++) {
      // Collect all the matching parties that share the best score
      const currentMatching = this._recommendation.matchings[i]
      if (this.partyIdMap[currentMatching.responder.id] === '') { // TODO(BSC): Fix types
        continue
      } else if (!matchings[0] || currentMatching.matchValue === matchings[0].matchValue) {
        matchings.push(currentMatching)
      }
    }
    // If there is no best match, abort (something went wrong)
    if (!matchings[0]) {
      return
    }

    // Set remaining dict entries
    this.results['bestmatch'] = matchings.map(m => this.partyIdMap[m.responder.id])
    this.results['bestscore'] = matchings[0].matchValue
    this.results['country'] = 'LU'

    // Pass data to votematch and generate popup
    VotematchEU.results(this.results);
    VotematchEU.popup()
  }

  ngAfterViewInit() {
    this.translations = this.translationTemplate
      .createEmbeddedView({})
      .rootNodes.reduce((prev, curr) => ({ ...prev, [curr.id]: curr.textContent }), {})
  }

  navigateToMethodology() {
    this.router.navigate(['/methodology'], { fragment: 'smartmap' })
  }

  trackSharingTrigger() {
    this.trackingService.trackEvent('Matching', 'openSharingDialog')
  }

  onFilterGroupChanged(state: FilterGroupState) {
    this.filterGroupStateChanges.next(state)
  }

  onTabChanged(index: number) {
    this._updateQueryParams({ tab: index })
  }

  private _updateQueryParams(params) {
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: params,
      replaceUrl: true,
      queryParamsHandling: 'merge'
    })
  }

  private _updateResultSummary(elections, filterGroupState: FilterGroupState, recommendation) {
    if (!(elections && filterGroupState && recommendation)) {
      return
    }
    const election = elections.find(e => e.id === filterGroupState.election)
    if (!election) {
      return
    }
    const district = election.districts.find(d => d.id === filterGroupState.district)
    this.resultSummary = {
      district: district.name,
      nofSeats: `${district.seats}`,
      nofCandidates: `${recommendation.matchings.length}`
    }
  }

  private _updateNofSeats(elections, filterGroupState: FilterGroupState) {
    if (!elections) {
      return
    }
    const election = elections.find(e => e.id === filterGroupState.election)
    if (!election) {
      return
    }
    const district = election.districts.find(d => d.id === filterGroupState.district)
    this.nofSeats = district.seats
  }

  private _getListItemLabels(matching: Matching) {
    let title = ''
    let description = ''
    let matchingValue = matching.matchValue
    if (matching.responderType === 'Candidate') {
      const candidate = matching.responder as Candidate
      if (candidate) {
        title = `${candidate.firstname} ${candidate.lastname}`
        description = candidate.party ? candidate.party.name : ''
        if (candidate.nofAnswers === 0) {
          description += ` (${this.getTranslatedMessage('no-answers')})`
          matchingValue = null
        }
      } else {
        title = this.getTranslatedMessage('no-information')
      }
    } else if (matching.responderType === 'Party') {
      const party = matching.responder as Party
      if (party) {
        title = party.name
      } else {
        title = this.getTranslatedMessage('no-information')
      }
    }
    return { title, description, matchingValue }
  }

  private _getFilterGroupState(options: RecommendationOptions) {
    return {
      election: options.electionId,
      district: options.districtId,
      responderType: options.responderType
    }
  }

  private _getElections() {
    return this.apollo
      .query<GetElectionsQuery>({
        query: GetElections
      })
      .pipe(map(({ data }) => data.elections))
  }

  private _getRecommendation(recommendationId: string, offset: number, limit: number): any {
    return this.apollo
      .query<GetRecommendationQuery>({
        query: GetRecommendation,
        variables: {
          recommendationId,
          offset,
          limit
        }
      })
      .pipe(map(({ data }) => ({ ...data.recommendation })))
  }

  private _doCreateRecommendation(filterGroupState: FilterGroupState): boolean {
    if (stateIsEqual(filterGroupState, this._filterGroupState) || !stateIsValid(filterGroupState)) {
      return false
    } else {
      return true
    }
  }

  private _doGetRecommendation(rid) {
    return rid && !this._recommendation
  }

  private createRecommendation(filterGroupState: FilterGroupState): Observable<Recommendation> {
    const answers = this.answerService.getAnswers()
    const surveyData = this.browserStorage.getItem(USER_SURVEY_KEY) || ''
    const options: RecommendationOptions = {
      responderType: filterGroupState.responderType,
      electionId: filterGroupState.election,
      districtId: filterGroupState.district,
      voterId: this.voterIdService.getVoterId(),
      answers,
      surveyData
    }
    return this.apollo
      .mutate<CreateRecommendationMutation>({
        mutation: CreateRecommendation,
        variables: {
          options
        }
      })
      .pipe(map(({ data }) => ({ ...data.createRecommendation })))
  }

  goToUserSurvey() {
    this.router.navigate(['matching', 'questions-about-you'], { queryParams: { fwd: true } })
  }

  onMatchingSelected(matching: Matching) {
    if (matching.responder) {
      if (matching.responderType === 'Candidate') {
        this.router.navigate(['matching', 'profile', 'candidate', matching.responder.id], {
          queryParamsHandling: 'preserve'
        })
      } else if (matching.responderType === 'Party') {
        this.router.navigate(['matching', 'profile', 'party', matching.responder.id], {
          queryParamsHandling: 'preserve'
        })
      }
    }
  }

  onSmartmapItemSelect(itemId) {
    const selectedMatching = this._recommendation.matchings.filter(
      matching => matching.responderId === itemId
    )
    if (selectedMatching.length === 1) {
      this.onMatchingSelected(selectedMatching[0])
    }
  }

  private getTranslatedMessage(msg: string): string {
    return this.translations[msg]
  }
}

export function getValue(responder: Responder, key: string) {
  if (!responder) {
    return null
  }
  const result = responder.values.find(entry => entry.key === key)
  return result ? result.value : null
}

export function getSmartmapLegendItems(
  recommendation: Recommendation
): { label: string; color: string }[] {
  const partyColors: { [partyName: string]: string } = {}
  recommendation.matchings
    .filter(matching => !!matching.responder && matching.responder.nofAnswers > 0)
    .forEach((matching: Matching) => {
      if (matching.responderType === 'Candidate') {
        const candidate = matching.responder as Candidate
        if (candidate.party) {
          const label = candidate.party.abbreviation || candidate.party.name
          partyColors[label] = '#' + getValue(candidate.party, 'color')
        }
      } else if (matching.responderType === 'Party') {
        const party = matching.responder as Party
        const label = party.abbreviation || party.name
        partyColors[label] = '#' + getValue(party, 'color')
      }
    })
  return Object.keys(partyColors).map(k => {
    return { label: k, color: partyColors[k] }
  })
}

export function getSmartmapPositions(recommendation: Recommendation): Position[] {
  return recommendation.matchings
    .filter(matching => !!matching.responder && matching.responder.nofAnswers > 0)
    .map((matching: Matching) => {
      if (matching.responderType === 'Candidate') {
        const candidate = matching.responder as Candidate
        const candidateLabel = candidate.party
          ? `${candidate.firstname} ${candidate.lastname} (${candidate.party.name})`
          : `${candidate.firstname} ${candidate.lastname}`
        return {
          id: candidate.id,
          color: '#' + getValue(candidate.party, 'color'),
          label: candidateLabel,
          ...candidate.smartmapPosition
        }
      } else if (matching.responderType === 'Party') {
        const party = matching.responder as Party
        return {
          id: party.id,
          color: '#' + getValue(party, 'color'),
          label: party.name,
          ...party.smartmapPosition
        }
      }
    })
}

function sleep(ms) {
  return new Promise(resolve => setTimeout(resolve, ms));
}
