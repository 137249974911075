export const environment = {
  production: true,
  demo: false,
  portierUrl: 'https://eu-portier.smartwielen.lu/graphql',
  sharing: {
    pageGrabberUrl: 'http://page-grabber.smartvote.ch/screenshot',
    twitterUsername: 'smartwielen'
  },
  emailerUrl: 'https://7degjxh594.execute-api.eu-central-1.amazonaws.com/production/send-email',
  emailerFromAddress: 'smartwielen.lu<noreply@smartwielen.lu>',
  contactFormToAddress: 'info@smartwielen.lu',
  matomo: {
    url: 'https://analytics.smartvote.ch',
    siteId: 4
  }
}
