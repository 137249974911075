import { Component, OnDestroy, Inject, LOCALE_ID } from '@angular/core'
import { Router } from '@angular/router'
import { Apollo } from 'apollo-angular'
import { map, tap } from 'rxjs/operators'
import { Observable, Subscription } from 'rxjs'

import { GetRecommendationCountQuery } from '../../__generated__/types'
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser'

const VIDEO_URL = {
  en: 'https://www.youtube.com/embed/58f0IcwHYEk',
  de: 'https://www.youtube.com/embed/zTDEZOptHlw',
  lb: 'https://www.youtube.com/embed/_kXBnEA1bLU',
  fr: 'https://www.youtube.com/embed/EpkH56dSwmE',
  pt: 'https://www.youtube.com/embed/erR1bBjJvZs'
}

const { GetRecommendationCount } = require('graphql-tag/loader!./home.page.graphql')
@Component({
  selector: 'svi-home',
  templateUrl: 'home.page.html',
  styleUrls: ['home.page.scss']
})
export class HomePage implements OnDestroy {
  recommendationCountLabel = '...'
  recommendationCount = 0
  videoUrl: SafeResourceUrl
  private sub: Subscription

  constructor(
    private router: Router,
    private apollo: Apollo,
    private sanitizer: DomSanitizer,
    @Inject(LOCALE_ID) private localeId
  ) {
    this.videoUrl = this.sanitizer.bypassSecurityTrustResourceUrl(VIDEO_URL[this.localeId])
    this.sub = this.getRecommendationCount().subscribe(count => {
      this.recommendationCountLabel = count.toLocaleString('en-GB')
      this.recommendationCount = count
    })
  }
  ngOnDestroy() {
    this.sub.unsubscribe()
  }
  startQuestionnaire() {
    this.router.navigate(['matching'])
  }
  private getRecommendationCount() {
    return this.apollo
      .query<GetRecommendationCountQuery>({
        query: GetRecommendationCount
      })
      .pipe(map(({ data }) => data.statistics.recommendationCount))
  }
}
