/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./answer.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./answer.component";
var styles_QuestionnaireAnswerComponent = [i0.styles];
var RenderType_QuestionnaireAnswerComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_QuestionnaireAnswerComponent, data: {} });
export { RenderType_QuestionnaireAnswerComponent as RenderType_QuestionnaireAnswerComponent };
function View_QuestionnaireAnswerComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "button", [], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.answerChange(50) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(1, 278528, null, 0, i2.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { ngClass: [0, "ngClass"] }, null), i1.ɵpod(2, { active: 0 }), (_l()(), i1.ɵted(-1, null, [" Neutral "]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _ck(_v, 2, 0, (_co.userAnswer.value === 50)); _ck(_v, 1, 0, currVal_0); }, null); }
export function View_QuestionnaireAnswerComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 52, "div", [["class", "svi-questionnaire-answer"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 29, "div", [["class", "answer-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 0, "div", [["class", "d-none d-sm-block"], ["style", "width: 100px;"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 22, "div", [["class", "answer-options"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 3, "button", [], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.answerChange(100) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(5, 278528, null, 0, i2.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { ngClass: [0, "ngClass"] }, null), i1.ɵpod(6, { active: 0 }), (_l()(), i1.ɵted(-1, null, ["Yes"])), (_l()(), i1.ɵeld(8, 0, null, null, 5, "button", [], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.answerChange(75) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(9, 278528, null, 0, i2.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { ngClass: [0, "ngClass"] }, null), i1.ɵpod(10, { active: 0 }), (_l()(), i1.ɵted(-1, null, [" Rather"])), (_l()(), i1.ɵeld(12, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["yes "])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_QuestionnaireAnswerComponent_1)), i1.ɵdid(15, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(16, 0, null, null, 5, "button", [], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.answerChange(25) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(17, 278528, null, 0, i2.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { ngClass: [0, "ngClass"] }, null), i1.ɵpod(18, { active: 0 }), (_l()(), i1.ɵted(-1, null, [" Rather"])), (_l()(), i1.ɵeld(20, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["no "])), (_l()(), i1.ɵeld(22, 0, null, null, 3, "button", [], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.answerChange(0) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(23, 278528, null, 0, i2.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { ngClass: [0, "ngClass"] }, null), i1.ɵpod(24, { active: 0 }), (_l()(), i1.ɵted(-1, null, ["No"])), (_l()(), i1.ɵeld(26, 0, null, null, 4, "div", [["class", "no-answer d-none d-sm-block"]], [[2, "hidden", null]], null, null, null, null)), (_l()(), i1.ɵeld(27, 0, null, null, 3, "button", [], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.answerChange((0 - 9)) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(28, 278528, null, 0, i2.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { ngClass: [0, "ngClass"] }, null), i1.ɵpod(29, { active: 0 }), (_l()(), i1.ɵted(-1, null, ["No answer"])), (_l()(), i1.ɵeld(31, 0, null, null, 21, "div", [["class", "weight-container-outer"]], null, null, null, null, null)), (_l()(), i1.ɵeld(32, 0, null, null, 20, "div", [["class", "weight-container-inner"]], null, null, null, null, null)), (_l()(), i1.ɵeld(33, 0, null, null, 14, "div", [["class", "weight-options"]], null, null, null, null, null)), (_l()(), i1.ɵeld(34, 0, null, null, 1, "div", [["class", "title"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Weight your answer"])), (_l()(), i1.ɵeld(36, 0, null, null, 3, "button", [], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.weightChange(0.5) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(37, 278528, null, 0, i2.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { ngClass: [0, "ngClass"] }, null), i1.ɵpod(38, { active: 0 }), (_l()(), i1.ɵeld(39, 0, null, null, 0, "i", [["class", "fa fa-minus"]], null, null, null, null, null)), (_l()(), i1.ɵeld(40, 0, null, null, 3, "button", [], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.weightChange(1) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(41, 278528, null, 0, i2.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { ngClass: [0, "ngClass"] }, null), i1.ɵpod(42, { active: 0 }), (_l()(), i1.ɵeld(43, 0, null, null, 0, "i", [["class", "fa fa-equals"]], null, null, null, null, null)), (_l()(), i1.ɵeld(44, 0, null, null, 3, "button", [], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.weightChange(2) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(45, 278528, null, 0, i2.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { ngClass: [0, "ngClass"] }, null), i1.ɵpod(46, { active: 0 }), (_l()(), i1.ɵeld(47, 0, null, null, 0, "i", [["class", "fa fa-plus"]], null, null, null, null, null)), (_l()(), i1.ɵeld(48, 0, null, null, 4, "div", [["class", "no-answer d-block d-sm-none"]], [[2, "hidden", null]], null, null, null, null)), (_l()(), i1.ɵeld(49, 0, null, null, 3, "button", [], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.answerChange((0 - 9)) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(50, 278528, null, 0, i2.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { ngClass: [0, "ngClass"] }, null), i1.ɵpod(51, { active: 0 }), (_l()(), i1.ɵted(-1, null, ["No answer"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _ck(_v, 6, 0, (_co.userAnswer.value === 100)); _ck(_v, 5, 0, currVal_0); var currVal_1 = _ck(_v, 10, 0, (_co.userAnswer.value === 75)); _ck(_v, 9, 0, currVal_1); var currVal_2 = _co.isStandard5Question; _ck(_v, 15, 0, currVal_2); var currVal_3 = _ck(_v, 18, 0, (_co.userAnswer.value === 25)); _ck(_v, 17, 0, currVal_3); var currVal_4 = _ck(_v, 24, 0, (_co.userAnswer.value === 0)); _ck(_v, 23, 0, currVal_4); var currVal_6 = _ck(_v, 29, 0, (_co.userAnswer.value === (0 - 9))); _ck(_v, 28, 0, currVal_6); var currVal_7 = _ck(_v, 38, 0, (_co.userAnswer.weight === 0.5)); _ck(_v, 37, 0, currVal_7); var currVal_8 = _ck(_v, 42, 0, (_co.userAnswer.weight === 1)); _ck(_v, 41, 0, currVal_8); var currVal_9 = _ck(_v, 46, 0, (_co.userAnswer.weight === 2)); _ck(_v, 45, 0, currVal_9); var currVal_11 = _ck(_v, 51, 0, (_co.userAnswer.value === (0 - 9))); _ck(_v, 50, 0, currVal_11); }, function (_ck, _v) { var _co = _v.component; var currVal_5 = (_co.responderType === "Candidate"); _ck(_v, 26, 0, currVal_5); var currVal_10 = (_co.responderType === "Candidate"); _ck(_v, 48, 0, currVal_10); }); }
export function View_QuestionnaireAnswerComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "svi-questionnaire-answer", [], null, null, null, View_QuestionnaireAnswerComponent_0, RenderType_QuestionnaireAnswerComponent)), i1.ɵdid(1, 49152, null, 0, i3.QuestionnaireAnswerComponent, [], null, null)], null, null); }
var QuestionnaireAnswerComponentNgFactory = i1.ɵccf("svi-questionnaire-answer", i3.QuestionnaireAnswerComponent, View_QuestionnaireAnswerComponent_Host_0, { userAnswer: "userAnswer", number: "number", questionType: "questionType", responderType: "responderType" }, { answerChanged: "answerChanged" }, []);
export { QuestionnaireAnswerComponentNgFactory as QuestionnaireAnswerComponentNgFactory };
