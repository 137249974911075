var __assign = (this && this.__assign) || Object.assign || function(t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
        s = arguments[i];
        for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
            t[p] = s[p];
    }
    return t;
};
import { EventEmitter, OnDestroy } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { TrackingService } from '../../core/tracking.service';
var FilterGroupState = /** @class */ (function () {
    function FilterGroupState() {
        this.district = '';
        this.name = '';
        this.party = '';
        this.incumbent = '';
        this.elected = '';
        this.gender = '';
    }
    return FilterGroupState;
}());
export { FilterGroupState };
var DatabaseFilterGroupComponent = /** @class */ (function () {
    function DatabaseFilterGroupComponent(fb, trackingService) {
        var _this = this;
        this.trackingService = trackingService;
        this._disabled = false;
        this.search = new EventEmitter();
        this.reset = new EventEmitter();
        this._state = new FilterGroupState();
        this.form = fb.group(this._state);
        this._sub = this.form.valueChanges.subscribe(function (state) {
            _this._state = __assign({}, _this.state, state);
            if (JSON.stringify(_this._state) !== JSON.stringify(new FilterGroupState())) {
                _this.form.markAsDirty();
            }
            else {
                _this.form.markAsPristine();
            }
        });
    }
    Object.defineProperty(DatabaseFilterGroupComponent.prototype, "disabled", {
        get: function () {
            return this._disabled;
        },
        set: function (value) {
            this._disabled = value;
            if (value) {
                this.form.disable({ emitEvent: false });
            }
            else {
                this.form.enable({ emitEvent: false });
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DatabaseFilterGroupComponent.prototype, "state", {
        get: function () {
            return this._state;
        },
        set: function (value) {
            this._setState(value);
        },
        enumerable: true,
        configurable: true
    });
    DatabaseFilterGroupComponent.prototype.ngOnDestroy = function () {
        this._sub.unsubscribe();
    };
    DatabaseFilterGroupComponent.prototype.onSearch = function () {
        this.trackingService.trackEvent('Profile', 'Search', JSON.stringify(this.form.value));
        this.form.markAsDirty();
        this.search.emit(this.state);
    };
    DatabaseFilterGroupComponent.prototype.onReset = function () {
        this._setState();
        this.reset.emit();
        this.form.markAsPristine();
    };
    DatabaseFilterGroupComponent.prototype._setState = function (state) {
        if (state === void 0) { state = new FilterGroupState(); }
        this._state = state;
        if (JSON.stringify(this._state) !== JSON.stringify(new FilterGroupState())) {
            this.form.markAsDirty();
        }
        else {
            this.form.markAsPristine();
        }
        this.form.patchValue(this._state, { emitEvent: false });
    };
    return DatabaseFilterGroupComponent;
}());
export { DatabaseFilterGroupComponent };
