<div class="demo-reset-layover">
  <div class="demo-reset-layover__inner">
    <div class="content">
      <h2 i18n>Resetting demo mode</h2>
      <div class="text">
        <p i18n>
          Choose "Reset now" to delete all your answers and return back to the home page. Choose
          "Cancel" to continue with your answers.
        </p>
        <p i18n>
          <ng-container>If you make no choice, the page will be reset in </ng-container
          >{{ remainingSeconds }}<ng-container> seconds</ng-container>.
        </p>
      </div>
      <div class="actions">
        <button class="button" (click)="cancel()" i18n>Cancel</button>
        <button class="button button-accept" (click)="resetNow()" i18n>Reset now</button>
      </div>
    </div>
  </div>
</div>
